<template>
  <TemplateView :breadcrumb="false">
    <div class="gpt">
      <div class="history-list" ref="listRef">
        <div class="loading-box" v-if="loading">
          <img
            src="../../assets/images/new_icon/loading.png"
            class="loading"
            width="30"
            height="30"
          />
        </div>
        <div
          class="list-item"
          v-for="(item, index) in historyList"
          :key="index"
        >
          <div class="keyword">
            <div class="time"></div>
            <div class="content">{{ item.keyword }}</div>
          </div>
          <div class="result">
            <div class="time"></div>
            <div class="content">{{ item.result }}</div>
          </div>
        </div>
      </div>
      <div class="input-wrap">
        <el-input
          type="textarea"
          :rows="2"
          resize="none"
          v-model="keyword"
          placeholder="来提问点什么吧... (Enter直接发送)"
          @keydown.native="keydown"
        ></el-input>
        <div class="img-wrap" @click="sendMessage()">
          <img src="../../assets/images/new_icon/send.png" alt="" />
        </div>
      </div>
    </div>
  </TemplateView>
</template>

<script setup>
import { ref, onBeforeUnmount, watch, nextTick, onMounted } from "vue";
import { Message } from "element-ui";
import constant from "@/utils/constant.js";
import Cookies from "js-cookie";
import systemAPI from "@/api/system.js";



const listRef=ref(null);
const keyword = ref("");
const historyList = ref([]);

function keydown(e) {
  if (e.keyCode == 13 && e.ctrlKey) {
    e.cancelBubble = true; //ie阻止冒泡行为
    e.stopPropagation(); //Firefox阻止冒泡行为
    // 阻止原生的换行事件
    e.preventDefault();
    // 手动换行
    keyword.value += "\n";
  } else if (e.keyCode == 13) {
    e.cancelBubble = true; //ie阻止冒泡行为
    e.stopPropagation(); //Firefox阻止冒泡行为
    // 阻止原生的换行事件
    e.preventDefault();
    sendMessage();
  }
}

const sending = ref(false);
let eventSource = null;

function sendMessage() {
  if (!keyword.value) {
    Message.warning("请输入问题～");
    return;
  }
  if (sending.value) {
    Message.warning("请等上一次结果返回后继续查询～");
    return;
  }

  // 将发送状态改为true
  sending.value = true;
  historyList.value.push({
    result: "加载中...",
    keyword: keyword.value,
    created_at: "",
  });
  // 发送之前重置打字机状态
  closeSSE();
  nextTick(()=>{
    listRef.value.scrollTop=listRef.value.scrollHeight;
  });

  eventSource = new EventSource(
    `${constant.URL}/system/chatgpt?message=${
      keyword.value
    }&access-token=${Cookies.get(constant.tokenName)}&model=qwen-53c7d0abd15b452782ef5bbee393ba4e&open_sse=1`);
  eventSource.onerror = () => {
    eventSource.close();
    eventSource = null;
    sending.value=false;
  };
  eventSource.addEventListener("result", handleEventResult);
  keyword.value = "";
}

let timer = null;
let textIndex = 0;
let aiResult = ref("");
watch(aiResult, () => {
  typewriter();
});

function typewriter() {
  clearTimeout(timer);
  listRef.value.scrollTop=listRef.value.scrollHeight;
  if (textIndex < aiResult.value.length) {
    historyList.value.at(-1).result += aiResult.value.charAt(textIndex);
    textIndex++;
    timer = setTimeout(typewriter, 30);
  } else {
    clearTimeout(timer);
  }
}

function handleEventResult(event) {
  if(historyList.value.at(-1).result === "加载中..."){
    historyList.value.at(-1).result = "";
  }
  const eventData = JSON.parse(event.data);
  if (eventData.error?.length) {
    aiResult.value = eventData.message;
  } else {
    aiResult.value = eventData.output.text;
  }
}

function closeSSE() {
  if (eventSource) {
    eventSource.close();
    eventSource = null;
  }
  textIndex = 0;
  aiResult.value = "";
  clearTimeout(timer);
}


const loading=ref(false);
const page=ref(1);
// 获取历史记录
function getHistoryList(type="get"){
  loading.value=true;
  systemAPI.systemChatgptList({
    page:page.value,
    pageSize:10,
    model:"qwen-53c7d0abd15b452782ef5bbee393ba4e"
  }).then(res=>{
    res.list.forEach(item=>{
      historyList.value.unshift(item);
    });
    nextTick(()=>{
      if(type==="get"){
        listRef.value.scrollTop=listRef.value.scrollHeight;
      }
      loading.value=false;
    });
  });
}
getHistoryList();

onMounted(()=>{
  listRef.value.addEventListener("scroll",handleScroll);
});
function handleScroll(e){
  if(loading.value) return;
  if(e.target.scrollTop<=80){
    page.value++;
    getHistoryList("append");
  }
}

onBeforeUnmount(() => {
  listRef.value.removeEventListener("scroll",handleScroll);
  closeSSE();
});
</script>

<style lang="scss" scoped>
.gpt {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .history-list {
    flex-grow: 1;
    background-color: #f7f7f7;
    padding: 20px;
    width: 100%;
    overflow-y: auto;
    .loading-box{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .list-item {
      width: 100%;
      font-size: 16px;
      .keyword {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        width: 100%;
        .content{
          max-width: 75%;
          background-color: #4a79ff;
          color: white;
          padding: 10px;
          border-radius: 8px 0px 8px 8px;
        }
      }
      .result {
        display: flex;
        justify-content: flex-start;
        margin-top: 30px;
        .content{
          max-width: 75%;
          background-color: white;
          padding: 10px;
          border-radius: 0px 8px 8px 8px;
          white-space: pre-wrap;
        }
      }
    }
  }
  .input-wrap {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 20px;
    background-color: #f7f7f7;
    .img-wrap {
      background-color: #4a79ff;
      height: 50px;
      width: 50px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
  ::v-deep .input-wrap {
    .el-textarea {
      textarea {
        background-color: #f7f7f7;
      }
    }
  }
}
</style>
